import CoreMixin from '@/mixins/core.mixin';
import DataTableLayout from '@/components/core/layouts/DataTableLayout';
import DataTableActions from '@/components/core/datatable/DataTableActions';
import NoData from '@/components/core/datatable/NoData';
import ModalFormLayout from '@/components/core/layouts/ModalFormLayout';
import ConfirmationModal from '@/components/core/modals/ConfirmationModal';

export default {
  mixins: [CoreMixin],
  components: {
    NoData,
    DataTableLayout,
    DataTableActions,
    ModalFormLayout,
    ConfirmationModal
  }
};
