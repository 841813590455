<template>
  <v-dialog
    v-model="dialog"
    overlay-opacity="0.95"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    :max-width="options.maxWidth"
  >
    <v-card
      :width="options.width"
      class="mt-0 fill-height flat"
      style="border-radius-top: 0px"
    >
      <v-toolbar
        :dark="options.color !== 'white' && !options.flat"
        :flat="options.flat"
        :color="options.color"
        class="mb-0"
      >
        <v-toolbar-title
          ><div>
            <div class="font-weight-bold">{{ options.title }}</div>
            <p class="caption mb-0">{{ options.subtitle }}</p>
          </div></v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="pt-4">
        <slot name="content"> </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" text color=" shadow-sm" @click="cancel">
          Cancel
        </v-btn>
        <v-btn color="primary" :loading="loading" depressed @click="submit">
          {{ options.submitButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
const defaultOptions = {
  flat: true,
  color: 'primary darken-1',
  maxWidth: '600px',
  width: '600px',
  title: '',
  subtitle: '',
  submitButtonText: 'Submit'
};

export default {
  name: 'ModalFormLayout',
  props: {
    options: {
      type: Object,
      default: () => defaultOptions
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  created() {
    this.unWatchOptions = this.$watch(
      'options',
      (cur) => {
        for (const k in defaultOptions) {
          if (!cur[k] && typeof cur[k] !== 'boolean')
            cur[k] = defaultOptions[k];
        }
      },
      { immediate: true }
    );
  },
  methods: {
    toggle() {
      this.dialog = !this.dialog;
    },

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    cancel() {
      this.$emit('cancel', null);
      this.$emit('close', null);
      this.dialog = !this.dialog;
    },

    submit() {
      this.$emit('submit', null);
      this.$emit('close', null);
    }
  }
};
</script>