<template>
  <v-row justify="center" class="px-0 ma-0">
    <v-col class="col-12 pa-0">
      <v-card rounded="md" flat :class="shadow ? 'shadow' : ''">
        <v-toolbar flat class="pa-0 pt-4 px-md-4">
          <div class="col-8 pa-0">
            <span class="font-weight-bold">{{ options.title }}</span>
            <p
              class="
                text-truncate
                body-2
                text-weight-light
                grey--text
                text--darken-3
              "
            >
              {{ options.subtitle }}
            </p>
          </div>
          <v-spacer></v-spacer>

          <slot name="actions"> </slot>

          <!-- Search Button -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="!options.hideSearch"
                v-on="on"
                v-bind="attrs"
                @click="toggleSearchBar"
                icon
                class="mr-4"
                ><v-icon>{{
                  !options.showSearch ? 'mdi-magnify' : 'mdi-close'
                }}</v-icon></v-btn
              >
            </template>
            <span>{{ options.showSearch ? 'Close Search' : 'Search' }}</span>
          </v-tooltip>

          <!-- Create Button -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="!options.hideButton"
                v-on="on"
                v-bind="attrs"
                depressed
                :class="`text-capitalize ${isMobile ? '' : 'shadow'}`"
                color="primary"
                @click="add()"
                :icon="isMobile"
                :loading="loading || options.loading"
              >
                <v-icon v-if="isMobile">mdi-plus</v-icon>
                <span v-else>{{ options.buttonText }}</span>
              </v-btn>
            </template>
            <span>{{ options.buttonText }}</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-title
          v-if="displaySearchBox"
          class="px-md-8 pt-4 pb-2 justify-start"
        >
          <v-responsive :max-width="!isMobile ? '300' : '100%'">
            <v-text-field
              background-color="#eee"
              dense
              flat
              solo
              clear-icon="mdi-close-circle"
              clearable
              single-line
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search..."
              v-model="search"
              ref="searchbar"
              @keyup="searching"
            ></v-text-field>
          </v-responsive>
        </v-card-title>
        <v-overlay
          :value="loading || options.loading"
          absolute
          :dark="false"
          color="#fff"
          opacity="0.9"
        >
          <v-progress-circular
            color="primary darken-2"
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card-text class="px-8">
          <slot name="content" default> </slot>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
const defaultOptions = {
  hideSearch: false, // removes search button totally from view
  showSearch: true, // toggle search bar
  buttonText: 'Create',
  hideButton: false,
  title: '',
  subtitle: '',
  loading: false
};

export default {
  name: 'DataTableLayout',
  props: {
    options: {
      type: Object,
      default: () => defaultOptions
    },

    loading: {
      type: Boolean,
      default: false
    },

    shadow: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    unWatchOptions: null,
    search: ''
  }),

  created() {
    this.unWatchOptions = this.$watch(
      'options',
      cur => {
        for (const k in defaultOptions) {
          if (!cur[k] && typeof cur[k] !== 'boolean')
            cur[k] = defaultOptions[k];
        }

        //unwatch options
        if (this.unWatchOptions) this.unWatchOptions();
      },
      { immediate: true }
    );
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    displaySearchBox() {
      if (this.options.hideSearch) return false;

      return this.options.showSearch;
    }
  },

  methods: {
    toggleSearchBar() {
      this.options.showSearch = !this.options.showSearch;
      if (this.options.showSearch) {
        setTimeout(() => this.$refs.searchbar.focus(), 500);
      }
    },
    async searching() {
      this.$emit('search', this.search);
    },
    async add() {
      this.$emit('add', null);
    }
  }
};
</script>
