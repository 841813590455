<template>
  <img
    src="../../../assets/img/people_search.svg"
    alt=""
    class="mx-auto mb-4 mt-12"
    style="display: block; width: 170px !important"
  />
</template>
<script>
export default {
  name: 'NoData'
};
</script>

