<template>
  <div>
    <template v-if="!isMobile && actions.length <= 4">
      <v-tooltip v-for="item in actions" :key="item.action" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="onActionClicked(item.actionCallBack)"
          >
            <v-icon color="#7f7f7f">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        {{ item.action }}
      </v-tooltip>
    </template>
    <v-menu v-else left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="item in actions"
          :key="item.action"
          @click="onActionClicked(item.actionCallBack)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.action }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'DataTableActions',
  props: {
    actions: {
      type: [],
      default: () => []
    },
    data: {
      type: Object,
      default: () => ({ index: -1, item: {} })
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  methods: {
    onActionClicked(actionCallBack) {
      if (!actionCallBack)
        throw new Error('action callback function not implement.');

      actionCallBack(this.data.index, this.data.item);
    }
  }
};
</script>