<template>
  <v-dialog v-model="dialog" overlay-opacity="0.95" persistent :max-width="600">
    <v-card class="mt-0 fill-height flat" style="border-radius-top: 0px">
      <v-card-title
        ><div>
          <div class="font-weight-bold">{{ title }}</div>
          <p class="caption mb-0">{{ subtitle }}</p>
        </div></v-card-title
      >
      <v-card-text class="pt-4">
        <slot name="content"> {{ message }} </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" text :color="denyColor" @click="deny">
          {{ denyText }}
        </v-btn>
        <v-btn
          :loading="loading"
          :color="confirmColor"
          depressed
          @click="confirm"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
import Logger from '@/lib/logger';

export default {
  name: 'ConfirmationModal',
  props: {
    confirmText: {
      type: String,
      default: 'Yes'
    },
    denyText: {
      type: String,
      default: 'No'
    },
    confirmColor: {
      type: String,
      default: 'primary'
    },
    denyColor: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },

    message: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    confirmFunc: null,
    deniedFunc: null
  }),
  created() {},
  methods: {
    async show(confirmCallback, deniedCallback = null) {
      if (!confirmCallback)
        throw new Error('confirmCallback parameter is required');

      this.dialog = true;
      this.confirmFunc = confirmCallback;
      this.deniedFunc = deniedCallback;
    },

    async confirm() {
      try {
        if (this.loading) return;

        this.loading = true;

        await this.confirmFunc();
        this.dialog = false;
      } catch (e) {
        Logger.error('[confirmation modal]: ', e);
      } finally {
        this.loading = false;
      }
    },

    deny() {
      if (this.deniedFunc) this.deniedFunc();
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.v-dialog__content {
  height: 50%;
}
</style>